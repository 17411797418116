import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';


import styles from "./service.module.css"

const ServicePage = (props: PageProps<GatsbyTypes.ServiceBySlugQuery>) => {
  return (
    <Layout location={props.location} shouldContainer={true}>
      <div className={styles.container}>

        <h1 className={styles.header}>{props.data.contentfulServices?.title}</h1>
        {//@ts-ignore 
          documentToReactComponents(props.data.contentfulServices?.fullPage?.json)
        }
      </div>

    </Layout>
  )
}

export default ServicePage



export const pageQuery = graphql`
query ServiceBySlug($slug: String!) {
  contentfulServices(slug: { eq: $slug }) {
    id
    title
    fullPage {
      json
    }
  }
}
`
